let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-exploration-dev-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-exploration-dev-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://cmbhxgnxhnhdbmh4ci4xg5e7vy.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://rj4zkp2is7.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://6t1vfo4b52.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.dev.exploration.forwoodsafety.com",
        WEBSOCKET: "wss://qud3gskc58.execute-api.us-west-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_wEptLworQ",
        APP_CLIENT_ID: "2ft96ma9ppvmthunrdts5p2d25",
        IDENTITY_POOL_ID: "us-west-2:9570346b-6940-45b2-b395-20be07c07ac4",
        USERPOOL_HOSTED_DOMAIN: "forwood-exploration-id-dev",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.dev.exploration.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.dev.exploration.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::506636788351:role/dev-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.3",
        HOSTNAME: "id.dev.exploration.forwoodsafety.com",
        COOKIE_DOMAIN: ".dev.exploration.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "618a3a64-739b-44e8-9592-0fe15bb51334",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.dev.exploration.forwoodsafety.com"
    }
};

export default config;
